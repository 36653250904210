import * as React from "react"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import ContactDetails from "../components/contactDetails"
import ContactInformation from "../components/contactInformation"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  return (
  <Layout>
    <div className='flex m-wrap m-col-reverse'>
      <div className='w-50 m-100'>
        <ContactInformation data={data.info} /> 
      </div>
      <div className='w-50 m-100'>
        <ContactDetails data={data.info} /> 
      </div>
    </div>
    <Spacer />
  </Layout>
  )
} 


export const Head = () => <Seo title="Home" />

export default IndexPage


export const query = graphql`
query ContactQuery {
  info:datoCmsContact {
    holiday
    introduction
    leasing
    address
    phone
    email
    instagram
    storeImage {
      gatsbyImageData
    }
    mapImage {
      gatsbyImageData
    }
  }
}
`