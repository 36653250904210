import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

const ContactDetails = ({data}) => {
  return (
  <div className='sticky max-250 m-mwa mla mr20 m-p20 m-pt0 m-mb40 m-m0'>
      <div className='bg-grey pos-rel'>
        <GatsbyImage image={data.storeImage?.gatsbyImageData} alt='Store Image' />
      </div>
      <div className='mt10 white-space f-small'>
        <div className='mb20'>Jonson Lane</div>
        139 Jonson St, Byron Bay NSW 2481<br/>
        {(data.phone ?
          <><a href={'tel:'+data.phone} className='link ul-link'>{data.phone}</a><br/></> :''
        )}
        <a href={'mailto:'+data.email} className='link ul-link'>{data.email}</a>
        <div className='mt20'>
        <span className='small uppercase grey mb5 inline'>(Social)</span><br/>
        ig. <a href={'https://www.instagram.com/'+data.instagram} target='_blank' rel='noreferrer' className='link ul-link'>{data.instagram}</a>
        </div>
      </div>
  </div>
  )
}

export default ContactDetails
