import * as React from "react"
import Spacer from "./spacer"

const ContactInformation = ({data}) => {
  return (
  <div className='contact-information p20 pt0'>
    <div className='h2 white-space'>
      {data.introduction}
    </div>
    <Spacer />
    <div className='flex m-wrap'>
      <div className='w-50 m-100  mla'>
        {( data.holiday ?
          <>
          <p className='m0 uppercase small'>Holiday Hours</p>
          <div className='white-space mb40 mt10'>
            {data.holiday}
          </div>
          </> : ''
        )}
        {( data.leasing ?
          <>
          <p className='m0 uppercase small'>Leasing</p>
          <div className='white-space mt10'>
            {data.leasing}
          </div>
          </> : ''
        )}
      </div>
    </div>
    <Spacer />
    <div className='ratio-2-1 bg-grey pos-rel overflow-hidden'>
      <iframe className='bg-image' title='iframe' src="https://snazzymaps.com/embed/433600" width="100%" height="100%" ></iframe>
    </div>
  </div>
  )
}

export default ContactInformation
